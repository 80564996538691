export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const VBreadcrumb = () => import('../../portal/components/ui/breadcrumb/VBreadcrumb.vue' /* webpackChunkName: "components/v-breadcrumb", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VAccordion = () => import('../../portal/components/ui/accordion/VAccordion.vue' /* webpackChunkName: "components/v-accordion", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VAccordionItem = () => import('../../portal/components/ui/accordion/VAccordionItem.vue' /* webpackChunkName: "components/v-accordion-item", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VBoxButton = () => import('../../portal/components/ui/button/VBoxButton.vue' /* webpackChunkName: "components/v-box-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VBurgerButton = () => import('../../portal/components/ui/button/VBurgerButton.vue' /* webpackChunkName: "components/v-burger-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VButton = () => import('../../portal/components/ui/button/VButton.vue' /* webpackChunkName: "components/v-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VButtonNew = () => import('../../portal/components/ui/button/VButtonNew.vue' /* webpackChunkName: "components/v-button-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VCircleButton = () => import('../../portal/components/ui/button/VCircleButton.vue' /* webpackChunkName: "components/v-circle-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VMarkerButton = () => import('../../portal/components/ui/button/VMarkerButton.vue' /* webpackChunkName: "components/v-marker-button", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VCheckbox = () => import('../../portal/components/ui/checkbox/VCheckbox.vue' /* webpackChunkName: "components/v-checkbox", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VChips = () => import('../../portal/components/ui/chips/VChips.vue' /* webpackChunkName: "components/v-chips", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VChipsSet = () => import('../../portal/components/ui/chips/VChipsSet.vue' /* webpackChunkName: "components/v-chips-set", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VDropdown = () => import('../../portal/components/ui/dropdown/VDropdown.vue' /* webpackChunkName: "components/v-dropdown", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VCounter = () => import('../../portal/components/ui/counter/VCounter.vue' /* webpackChunkName: "components/v-counter", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VIcon = () => import('../../portal/components/ui/icon/VIcon.vue' /* webpackChunkName: "components/v-icon", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VRub = () => import('../../portal/components/ui/icon/VRub.vue' /* webpackChunkName: "components/v-rub", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFilterCell = () => import('../../portal/components/ui/filter-cell/VFilterCell.vue' /* webpackChunkName: "components/v-filter-cell", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFile = () => import('../../portal/components/ui/input/VFile.vue' /* webpackChunkName: "components/v-file", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFileCard = () => import('../../portal/components/ui/input/VFileCard.vue' /* webpackChunkName: "components/v-file-card", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFileCardItem = () => import('../../portal/components/ui/input/VFileCardItem.vue' /* webpackChunkName: "components/v-file-card-item", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFileField = () => import('../../portal/components/ui/input/VFileField.vue' /* webpackChunkName: "components/v-file-field", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFileLoader = () => import('../../portal/components/ui/input/VFileLoader.vue' /* webpackChunkName: "components/v-file-loader", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VFileNew = () => import('../../portal/components/ui/input/VFileNew.vue' /* webpackChunkName: "components/v-file-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VInput = () => import('../../portal/components/ui/input/VInput.vue' /* webpackChunkName: "components/v-input", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VInputThousands = () => import('../../portal/components/ui/input/VInputThousands.vue' /* webpackChunkName: "components/v-input-thousands", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VOptionGroup = () => import('../../portal/components/ui/option-group/VOptionGroup.vue' /* webpackChunkName: "components/v-option-group", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VOrder = () => import('../../portal/components/ui/order/VOrder.vue' /* webpackChunkName: "components/v-order", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VOtpInput = () => import('../../portal/components/ui/otp-input/VOtpInput.vue' /* webpackChunkName: "components/v-otp-input", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VPopOver = () => import('../../portal/components/ui/pop-over/VPopOver.vue' /* webpackChunkName: "components/v-pop-over", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VProfilePicture = () => import('../../portal/components/ui/profile-picture/VProfilePicture.vue' /* webpackChunkName: "components/v-profile-picture", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VPreloader = () => import('../../portal/components/ui/preloader/VPreloader.vue' /* webpackChunkName: "components/v-preloader", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VProfilePreview = () => import('../../portal/components/ui/profile-preview/VProfilePreview.vue' /* webpackChunkName: "components/v-profile-preview", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VRange = () => import('../../portal/components/ui/range/VRange.vue' /* webpackChunkName: "components/v-range", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VScrollBox = () => import('../../portal/components/ui/scrollbox/VScrollBox.vue' /* webpackChunkName: "components/v-scroll-box", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSelect = () => import('../../portal/components/ui/select/VSelect.vue' /* webpackChunkName: "components/v-select", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSelectOption = () => import('../../portal/components/ui/select/VSelectOption.vue' /* webpackChunkName: "components/v-select-option", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSkeletonLoader = () => import('../../portal/components/ui/skeleton/VSkeletonLoader.vue' /* webpackChunkName: "components/v-skeleton-loader", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const SliderDot = () => import('../../portal/components/ui/slider/SliderDot.vue' /* webpackChunkName: "components/slider-dot", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSlider = () => import('../../portal/components/ui/slider/VSlider.vue' /* webpackChunkName: "components/v-slider", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const MediaSlide = () => import('../../portal/components/ui/swiper/MediaSlide.vue' /* webpackChunkName: "components/media-slide", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwiper = () => import('../../portal/components/ui/swiper/VSwiper.vue' /* webpackChunkName: "components/v-swiper", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwiperControlPanel = () => import('../../portal/components/ui/swiper/VSwiperControlPanel.vue' /* webpackChunkName: "components/v-swiper-control-panel", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwiperPagination = () => import('../../portal/components/ui/swiper/VSwiperPagination.vue' /* webpackChunkName: "components/v-swiper-pagination", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwiperPaginationNew = () => import('../../portal/components/ui/swiper/VSwiperPaginationNew.vue' /* webpackChunkName: "components/v-swiper-pagination-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VSwitch = () => import('../../portal/components/ui/switch/VSwitch.vue' /* webpackChunkName: "components/v-switch", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTab = () => import('../../portal/components/ui/tabs/VTab.vue' /* webpackChunkName: "components/v-tab", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTabBar = () => import('../../portal/components/ui/tabs/VTabBar.vue' /* webpackChunkName: "components/v-tab-bar", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTabBarNew = () => import('../../portal/components/ui/tabs/VTabBarNew.vue' /* webpackChunkName: "components/v-tab-bar-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTabBarScrollable = () => import('../../portal/components/ui/tabs/VTabBarScrollable.vue' /* webpackChunkName: "components/v-tab-bar-scrollable", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTabNew = () => import('../../portal/components/ui/tabs/VTabNew.vue' /* webpackChunkName: "components/v-tab-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTag = () => import('../../portal/components/ui/tag/VTag.vue' /* webpackChunkName: "components/v-tag", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTagBar = () => import('../../portal/components/ui/tag/VTagBar.vue' /* webpackChunkName: "components/v-tag-bar", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTagExtraCount = () => import('../../portal/components/ui/tag/VTagExtraCount.vue' /* webpackChunkName: "components/v-tag-extra-count", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTagNew = () => import('../../portal/components/ui/tag/VTagNew.vue' /* webpackChunkName: "components/v-tag-new", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTagNewWrapper = () => import('../../portal/components/ui/tag/VTagNewWrapper.vue' /* webpackChunkName: "components/v-tag-new-wrapper", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTextarea = () => import('../../portal/components/ui/textarea/VTextarea.vue' /* webpackChunkName: "components/v-textarea", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTextareaEditor = () => import('../../portal/components/ui/textarea/VTextareaEditor.vue' /* webpackChunkName: "components/v-textarea-editor", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VToast = () => import('../../portal/components/ui/toast/VToast.vue' /* webpackChunkName: "components/v-toast", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTooltip = () => import('../../portal/components/ui/tooltip/VTooltip.vue' /* webpackChunkName: "components/v-tooltip", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const VTooltipContent = () => import('../../portal/components/ui/tooltip/VTooltipContent.vue' /* webpackChunkName: "components/v-tooltip-content", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))
export const FadeHeightTransition = () => import('../../portal/components/ui/transition/FadeHeightTransition.vue' /* webpackChunkName: "components/fade-height-transition", webpackPrefetch: true */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
